<template>
  <div id="aside">
    <el-menu
        router
        :collapse="$store.state.base_store.collapseVal"
        :default-active="$route.path"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        :collapse-transition="false"
        class="el-menu-vertical-demo">
      <div class="title-box">
        <div class="title-text" v-if="!$store.state.base_store.collapseVal">
          <i class="iconfont icon-xiaochengxu"></i>
          小程序管理平台
        </div>
        <div v-else class="title-text">
          <i class="iconfont icon-xiaochengxu"></i>
        </div>
        <div class="version" v-if="!$store.state.base_store.collapseVal">
          v4.0.0
        </div>
      </div>
      <el-menu-item index="/wechat-admin">
        <i class="iconfont icon-shouye1"></i>
        <span slot="title">首页</span>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <i class="iconfont icon-wenzhang"></i>
          <span>文章管理</span>
        </template>
        <el-menu-item index="/wechat-admin/GroupManager">分类管理</el-menu-item>
        <el-menu-item index="/wechat-admin/ArticleManager">文章管理</el-menu-item>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="iconfont icon-yiyuan"></i>
          <span>医院管理</span>
        </template>
        <el-menu-item index="/wechat-admin/CancerManage">早癌数据</el-menu-item>
        <el-menu-item index="/wechat-admin/HospManage">医院管理</el-menu-item>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="iconfont icon-haoyou"></i>
          <span>小程序会员管理</span>
        </template>
        <el-menu-item index="/wechat-admin/UserManage">会员管理</el-menu-item>
        <el-menu-item index="/wechat-admin/OrderManager">支付订单</el-menu-item>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title">
          <i class="iconfont icon-yuyue"></i>
          <span>预约管理</span>
        </template>
        <el-menu-item index="/wechat-admin/Appointment">预约管理</el-menu-item>
      </el-submenu>
      <el-submenu index="6" v-if="$store.state.base_store.userInfo.role_name === '超级管理员'">
        <template slot="title">
          <i class="iconfont icon-jurassic_admin"></i>
          <span>超级管理员中心</span>
        </template>
        <el-menu-item index="/wechat-admin/AdminUser">用户管理</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Aside",
}
</script>

<style lang="scss" scoped>
#aside {
  //min-height: 100vh;
}

.el-menu {
  min-height: 100vh;
  border: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-width: 200px;
}

.iconfont {
  color: #ffffff;
  margin-right: 10px;
  position: relative;
  top: -1px
}

.title-text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
}

.icon-xiaochengxu {
  margin-right: 0;
  font-weight: normal;
  font-size: 25px;
  line-height: 80px;
  position: relative;
  top: 2px;
}

.el-menu {
  border: none;
}

.version{
  color: #fff;
  text-align: center;
  position: relative;
  top: -10px;
}
</style>
