<template>
  <div id="my-header">
    <div class="l-content">
      <div>
        <i v-if="!$store.state.base_store.collapseVal" class="iconfont icon-shouqi"
           @click="changeCollapse"></i>
        <i v-if="$store.state.base_store.collapseVal" class="iconfont icon-zhankai"
           @click="changeCollapse"></i>
      </div>
      <div class="breadcrumb-div">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>{{ $store.state.base_store.breadcrumbName[$route.path] }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="r-content">
      <el-dropdown trigger="hover">
      <span class="el-dropdown-link">
        <div class="user-name">
          欢迎 ，{{ $store.state.base_store.userInfo.username }}（{{ $store.state.base_store.userInfo.role_name }}）
        </div>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="$router.push('/wechat-admin/ChangePwd')" ><span ><i class="el-icon-edit-outline"></i>修改密码</span></el-dropdown-item>
          <el-dropdown-item @click.native="dologOutMet()"><span ><i class="el-icon-switch-button"></i>退出</span></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>

export default {
  name: "MainHeader",
  data() {
    return {}
  },
  methods: {
    changeCollapse() {
      this.$store.commit('changeCollapse')
    },
    dologOutMet() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit("doLogout")
        this.$router.push("/wechat-admin/login")
      })
    }
  },
}
</script>

<style scoped>
#my-header {
  height: 50px;
  background-color: #EBEEF5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.l-content {
  padding-left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;

  .el-button {
    border: none;
    background-color: transparent;
    font-size: 30px;
    padding: 5px;
  }
}

.breadcrumb-div {
  margin-left: 20px;
}

/deep/ .el-breadcrumb__inner {
  line-height: 50px;
  font-weight: bold !important;
  font-size:18px !important;
}

.iconfont {
  font-size: 30px !important;
  cursor: pointer;
}

.r-content {
  padding-right: 20px;
}

.el-avatar {
  cursor: pointer;
}

.user-name {
  cursor: pointer;
  padding-right: 10px;
}
</style>
