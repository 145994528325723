import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import "../src/assets/icon/iconfont.css"
import axios from 'axios'
import {hexMD5} from "@/plugins/md5";
import {getNowDate} from "@/plugins/getDate";

const ENV_NOW = document.querySelector("html").getAttribute("env_now");

if (ENV_NOW === "production") {
    axios.defaults.baseURL = '';
} else if (ENV_NOW === "dev") {
    axios.defaults.baseURL = 'https://www.endoangel-info.com/dev';
} else {
    // axios.defaults.baseURL = 'http://192.168.0.194:8081/dev';
    axios.defaults.baseURL = 'https://www.endoangel-info.com/dev';
    // axios.defaults.baseURL = 'http://192.168.0.56:8081/dev';
    // axios.defaults.baseURL = 'http://127.0.0.1:8081/dev';
}

// 设置请求拦截器
axios.interceptors.request.use(
    resquest => {
        let endoangel = hexMD5(getNowDate() + "endoangel")
        resquest.headers.token = store.state.base_store.userInfo.token
        resquest.headers.expireTime = Date.now() + 300000
        resquest.headers.endoangel = endoangel
        return resquest
    },
    error => {
        return Promise.reject(error)
    }
)

// 定义响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.data.code === "Error" && response.data.message === "未登录") {
        router.push('/wechat-admin/login')
    }
    if (response.data.code === "Error" && response.data.message === "登录已过期") {
        router.push('/wechat-admin/login')
    }
    return response
}, function (error) {
    if (error.response.status === 401) {
        // 跳转登录页
        router.push('/wechat-admin/login')
    }
    return Promise.reject(error)
})


Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
