<template>
  <div class="main-page">
    <el-container>
      <el-aside width="auto">
        <main-aside></main-aside>
      </el-aside>
      <el-main>
        <main-header></main-header>
        <div class="main">
          <el-card class="box-card main-card">
            <router-view/>
          </el-card>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import MainAside from "../components/MainAside";
import MainHeader from "../components/MainHeader";

export default {
  name: 'MainPageView',
  components: {MainAside, MainHeader}
}
</script>

<style scoped>
.main-page {
  min-height: 100vh;
}

.el-container {
  min-height: 100vh;
}

.el-main {
  padding: 0;
}

.main {
  padding: 20px;
}
</style>
