<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit('changeWindowSize',[window.innerWidth,window.innerHeight])
      })();
    };
  },
  created() {
    document.title = '武汉楚精灵医疗科技有限公司'
    this.$store.commit('changeWindowSize',[window.innerWidth,window.innerHeight])
  }
}
</script>

<style>
html{
  min-height: 100%;
}

body{
  min-height: 100vh;
  margin: 0;
}

#app{
  min-height: 100vh;
}
</style>
