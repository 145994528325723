function getNowDate() {
    var date = new Date();
    var sign1 = "-";
    var year = date.getFullYear() // 年
    var month = date.getMonth() + 1; // 月
    var day  = date.getDate(); // 日
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }
    return year + sign1 + month + sign1 + day;
}
module.exports = {
    getNowDate: getNowDate,
}