import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPageView from '../views/MainPageView.vue'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: "",
        redirect: to => {
            return {path: "/wechat-admin"}
        }
    },
    {
        path: '/wechat-admin/login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/wechat-admin',
        component: MainPageView,
        children: [
            {
                path: '',
                component: () => import('../pages/Index.vue')
            },
            {
                path: 'Appointment',
                component: () => import('../pages/Appointment/Appointment')
            },
            {
                path: 'AdminUser',
                component: () => import('../pages/AdminUser/AdminUser')
            },
            {
                path: 'ChangePwd',
                component: () => import('../pages/AdminUser/ChangePwd')
            },
            {
                path: 'UserManage',
                component: () => import('../pages/UserManage/UserManage')
            },
            {
                path: 'OrderManager',
                component: () => import('../pages/OrderManager/OrderManager')
            },
            {
                path: 'CancerManage',
                component: () => import('../pages/CancerManage/CancerManage')
            },
            {
                path: 'HospManage',
                component: () => import('../pages/HospManage/HospManage')
            },
            {
                path: 'AddHosp',
                component: () => import('../pages/HospManage/AddHosp')
            },
            {
                path: 'EditHosp',
                component: () => import('../pages/HospManage/EditHosp')
            },
            {
                path: 'ArticleManager',
                component: () => import('../pages/ArticleManager/ArticleManager')
            },
            {
                path: 'AddArticle',
                component: () => import('../pages/ArticleManager/AddArticle')
            },
            {
                path: 'EditArticle',
                component: () => import('../pages/ArticleManager/EditArticle')
            },
            {
                path: 'GroupManager',
                component: () => import('../pages/GroupManager/GroupManager')
            },
        ]
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('../pages/404.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path !== "/wechat-admin/login") {
        if (store.state.base_store.userInfo.token) {
            next()
        } else {
            next('/wechat-admin/login')
        }
    } else {
        next()
    }
})

export default router
