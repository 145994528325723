import Vue from 'vue'
import Vuex from 'vuex'
import base_store from "./base_store";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {base_store}
})
