export default {
    state: {
        collapseVal: false,
        breadcrumbName: {
            "/wechat-admin": "首页",
            "/wechat-admin/Appointment": "预约管理",
            "/wechat-admin/AdminUser": "用户管理",
            "/wechat-admin/ChangePwd": "修改密码",
            "/wechat-admin/OrderManager": "支付订单管理",
            "/wechat-admin/UserManage": "会员管理",
            "/wechat-admin/CancerManage": "早癌数据管理",
            "/wechat-admin/HospManage": "医院数据管理",
            "/wechat-admin/AddHosp": "新增医院",
            "/wechat-admin/EditHosp": "修改医院信息",
            "/wechat-admin/GroupManager": "分类管理",
            "/wechat-admin/ArticleManager": "文章管理",
            "/wechat-admin/AddArticle": "文章管理",
            "/wechat-admin/EditArticle": "文章管理",
        },
        windowSize: {
            width: 0,
            height: 0,
        },
        userInfo: JSON.parse(window.localStorage.getItem("userInfo")) || {}
    },
    mutations: {
        changeCollapse(state) {
            state.collapseVal = !state.collapseVal
        },
        changeWindowSize(state, windowData) {
            state.windowSize = {
                width: windowData[0],
                height: windowData[1],
            }
        },
        doLogin(state, userInfo) {
            state.userInfo = userInfo;
            window.localStorage.setItem("userInfo", JSON.stringify(userInfo))
        },
        doLogout(state) {
            window.localStorage.removeItem("userInfo");
            state.userInfo = {};
        }
    }
}